import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import HeaderWithLogin from "../components/HeaderWithLogin";
import Layout from "../components/Layout.js";
import HeroSectionWithVideo from "../components/HeroSectionWithVideo";
import Section from "../components/Section.js";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage";
import RenderMarkdown from "../components/utilities/RenderMarkdown";
import BenefitsList from "../components/BenefitsList";
import Cta from "../components/CTA";
import Famous from "../components/Famous";
import BenefitsGrid from "../components/BenefitsGrid";
import FancyContentsList from "../components/FancyContentsList";
import Reviews from "../components/Reviews";
import TestimonialsGrid from "../components/TestimonialsGrid";
import PricingTim from "../components/PricingTim.js";
import ContentsList from "../components/ContentsList";
import FiveStars from "../components/FiveStars";
import Video from "../components/Video";
import FAQs from "../components/FAQs";
import Guarantee from "../components/Guarantee";
import FinalText from "../components/FinalText";

export const UltimateSalesPageTemplate = ({ data }) => {
  return (
    <>
      <script async src="https://go.achtsamkeitsakademie.de/hp" />
      <HeaderWithLogin imageObject={data.headerObject.imageObject} />
      <Layout seoObject={data.seoObject} className="bg-brand-beige-40">
        {/* #################################### heroObject ####################################### */}
        <div className="relative h-full w-full">
          <div className="absolute bottom-0 right-0 w-2/3 ">
            <StaticImage
              src="../img/bg-berge-mint.png"
              loading="eager"
              objectFit="contain"
              alt=""
              placeholder="none"
            />
          </div>
          <div className="absolute bottom-0 left-0 w-1/3 transform -translate-x-1/2 translate-y-1/2">
            <StaticImage
              src="../img/bg-kreis-gold.png"
              loading="eager"
              objectFit="contain"
              alt=""
              placeholder="none"
            />
          </div>

          <HeroSectionWithVideo heroObject={data.heroObject} />
        </div>

        {/* #################################### heroObject ####################################### */}
        <Section className="bg-white">
          <Famous famousObject={data.famousObject} />
        </Section>

        {/* #################################### greetingsObject ####################################### */}
        <Section>
          <article className="flex flex-row flex-nowrap justify-between max-w-2xl mx-auto mb-10">
            <div className="flex flex-col justify-end">
              <RenderMarkdown
                markdownContent={data.greetingsObject.greeting}
                className="text-left"
              />
            </div>
            <div className="bg-white rounded-full">
              <PreviewCompatibleImage
                imageObject={data.greetingsObject.imageObject}
              />
            </div>
          </article>
          <article>
            <BenefitsList
              benefitsObject={data.greetingsObject.benefitsObject}
              classNameTitle="!text-left"
            />
          </article>
          <article className="flex flex-row justify-center my-5 sm:my-10">
            <PreviewCompatibleImage
              imageObject={data.greetingsObject.finalImageObject}
              className="w-1/4 max-w-[8rem]"
            />
          </article>
        </Section>

        {/* #################################### youKnowObject ####################################### */}
        <Section className="bg-brand-mint">
          <RenderMarkdown
            markdownContent={data.youKnowObject.title}
            className="text-white"
          />
        </Section>
        <Section>
          <article>
            <RenderMarkdown markdownContent={data.youKnowObject.introduction} />
            <div className="flex flex-row justify-center mx-auto space-x-2 mb-16 mt-12 max-w-2xl">
              <div className={`"flex justify-center items-center"}`}>
                <PreviewCompatibleImage
                  imageObject={data.youKnowObject.quoteObject.imageObject}
                  className={`w-20 h-20`}
                />
              </div>
              <div className="my-auto xl:w-auto mt-8">
                <RenderMarkdown
                  markdownContent={data.youKnowObject.quoteObject.quote}
                  isMarkdown={true}
                  whitespace="normal"
                />
              </div>
            </div>
            <BenefitsList
              benefitsObject={data.youKnowObject.benefitsObject}
              classNameTitle="!text-left"
            />
          </article>
        </Section>

        {/* #################################### mindfulnessObject ####################################### */}
        <Section classNameInnerContainer="space-y-5 sm:space-y-10">
          <RenderMarkdown markdownContent={data.mindfulnessObject.text} />
          <RenderMarkdown
            markdownContent={data.mindfulnessObject.quote}
            className="text-center !max-w-md"
          />
          <RenderMarkdown
            markdownContent={data.mindfulnessObject.benefitsText}
          />
        </Section>

        {/* #################################### invitationObject ####################################### */}
        {/* <div className="relative h-full w-full my-24">
          <div className="w-screen scale-y-100">
            <StaticImage
              src="../img/bg-fluss-gold-2.png"
              loading="lazy"
              objectFit="cover"
              alt=""
              placeholder="none"
              className="w-screen opacity-50"
            />
          </div>
          <Section
            className="absolute bottom-1/2 bg-brand-gold w-full"
            classNameInnerContainer="flex justify-center items-center"
          >
            <RenderMarkdown
              markdownContent={data.invitationObject.title}
              className="markWhite"
              whitespace="normal"
            />
          </Section>
        </div> */}
        <Section
          className=" bg-brand-gold w-full bg-opacity-80"
          classNameInnerContainer="flex justify-center items-center"
        >
          <RenderMarkdown
            markdownContent={data.invitationObject.title}
            className="markWhite"
            whitespace="normal"
          />
        </Section>
        <Section>
          <article>
            <BenefitsList
              classNameTitle="!text-left"
              benefitsObject={data.invitationObject.benefitsObject}
            />
            <RenderMarkdown
              markdownContent={data.invitationObject.quote}
              className="text-center my-12"
            />
            <RenderMarkdown markdownContent={data.invitationObject.text} />
            <div className="flex flex-row justify-center">
              <PreviewCompatibleImage
                imageObject={data.invitationObject.imageObject}
              />
            </div>
          </article>
        </Section>

        {/* #################################### innerPeaceObject ####################################### */}
        <Section className="bg-brand-olive text-white">
          <RenderMarkdown
            markdownContent={data.innerPeaceObject.kicker}
            className="text-center text-brand-green"
          />
          <RenderMarkdown
            markdownContent={data.innerPeaceObject.title}
            className="text-center markBrandGreen"
          />
          <PreviewCompatibleImage
            imageObject={data.innerPeaceObject.imageObject}
            whitespace="normal"
          />
          <RenderMarkdown
            markdownContent={data.innerPeaceObject.text}
            className="text-center"
            whitespace="normal"
          />
          <Cta
            ctaObject={data.innerPeaceObject.ctaObject}
            className="mt-5 mb-10"
          />
          <BenefitsGrid
            benefitsGridObject={data.innerPeaceObject.benefitsGridObject}
          />
        </Section>

        {/* #################################### testimonialTeaser ####################################### */}
        <Section>
          <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-3">
            <div className="flex flex-col space-y-3">
              <PreviewCompatibleImage
                imageObject={data.testimonialTeaser.imageObject}
                className="h-56 w-56 mx-auto rounded-full"
              />
              <RenderMarkdown
                markdownContent={data.testimonialTeaser.author}
                className="text-center max-w-sm"
                whitespace="normal"
              />

              <FiveStars className="justify-center" />
            </div>
            <div>
              <RenderMarkdown
                markdownContent={data.testimonialTeaser.quoteObject.quote}
              />
            </div>
          </div>
        </Section>

        {/* #################################### aboutObject ####################################### */}
        {/* negative bottom-margin to counter negative bottom-margin of StaticImage below Section*/}
        <Section className="mt-10 -mb:14 sm:-mb-26">
          <RenderMarkdown markdownContent={data.aboutObject.title} />
          <BenefitsList
            benefitsObject={data.aboutObject.benefitsObject}
            classNameTitle="!text-left"
            classNameIcon="!w-32 !h-32"
            mobileIconTop={true}
          />
        </Section>

        <div className="w-2/3 ml-auto -mb-10 sm:-mb-20">
          <StaticImage
            src="../img/bg-berge-green.png"
            loading="eager"
            objectFit="contain"
            alt=""
            placeholder="none"
          />
        </div>

        {/* #################################### academyLifeObject ####################################### */}
        <Section classNameInnerContainer="bg-white rounded-3xl pt-28 sm:pt-36 pb-5 sm:pb-10">
          <BenefitsList
            benefitsObject={data.academyLifeObject.benefitsObject}
          />
          <article className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5 justify-center max-w-2xl my-10 mx-auto space-x-1">
            <PreviewCompatibleImage
              imageObject={data.academyLifeObject.beforeImageObject}
            />
            <PreviewCompatibleImage
              imageObject={data.academyLifeObject.afterImageObject}
            />
          </article>
          <Cta ctaObject={data.academyLifeObject.ctaObject} />
        </Section>

        {/* #################################### reviewsObject ####################################### */}
        <Section>
          <Reviews reviewsObject={data.reviewsObject} />
        </Section>

        {/* #################################### coursesObject ####################################### */}
        <Section>
          <FancyContentsList contentsObject={data.coursesContentsObject} />
        </Section>

        {/* #################################### forWhoObject ####################################### */}
        <Section className="bg-brand-olive">
          <RenderMarkdown
            markdownContent={data.forWhoObject.title}
            className="text-brand-white"
          />
          <div className="bg-white rounded-xl w-fit mx-auto">
            <BenefitsGrid
              benefitsGridObject={data.forWhoObject.benefitsGridObject}
              hasEnzo={true}
            />
          </div>
        </Section>

        {/* #################################### pricingObject ####################################### */}
        <Section id="anmelden">
          <PricingTim pricingObject={data.pricingObject} />
        </Section>

        {/* #################################### contentsObject ####################################### */}
        <Section className="!px-0 !sm:px-10">
          <ContentsList contentsObject={data.contentsObject} />
        </Section>

        {/* #################################### moreTestimonialsObject ####################################### */}
        <Section
          className="bg-brand-green text-white"
          classNameInnerContainer="max-w-[100rem]"
        >
          <TestimonialsGrid
            testimonialsGridObject={data.testimonialsGridObject}
          />
        </Section>

        {/* #################################### coursePackageObject ####################################### */}
        <Section className="bg-white">
          <RenderMarkdown
            markdownContent={data.coursePackageObject.title}
            whitespace="normal"
            className="m-0 text-center space-y-3 sm:space-y-5"
          />

          <Video
            videoObject={data.coursePackageObject.video}
            loading="lazy"
            className="my-16 max-w-2xl mx-auto"
          />
          <BenefitsList
            benefitsObject={data.coursePackageObject.benefitsObject}
          />
          <Cta
            ctaObject={data.coursePackageObject.ctaObject}
            className="my-10"
          />
        </Section>

        {/* #################################### coachesObject ####################################### */}
        <Section>
          <FancyContentsList
            contentsObject={data.coachesObject}
            isEvenOdd={true}
            mobileImagesFullSize
          />
        </Section>

        {/* #################################### noRiskObject ####################################### */}
        <Section>
          <Guarantee guaranteeObject={data.guaranteeObject} />
        </Section>

        {/* #################################### videoObject ####################################### */}
        <Section>
          <div>
            <RenderMarkdown
              markdownContent={data.testimonialVideoObject.title}
              className="text-center"
            />
            <Video videoObject={data.testimonialVideoObject.video} />
          </div>
        </Section>

        {/* #################################### faqsObject ####################################### */}
        <Section className="bg-brand-mint">
          <FAQs
            faqsObject={data.faqsObject}
            className="bg-brand-beige-40 rounded-3xl w-fit px-10"
          />
          <Cta ctaObject={data.faqsCtaObject} className="mt-10" />
        </Section>

        {/* #################################### final ####################################### */}
        <div className="relative">
          <StaticImage
            className="!absolute w-full h-full object-left-bottom"
            objectFit="cover"
            src="../img/imported_with_StaticImage/feld1-1.jpeg"
          />

          <Section className="relative bg-white bg-opacity-90">
            <FinalText finalTextObject={data.finalTextObject} />
          </Section>
        </div>

        {/* #################################### pricingObject ####################################### */}
        <Section id="anmelden2">
          <PricingTim
            pricingObject={data.pricingObject}
            isAlwaysOffer
            hasSecondPaymentIcons
          />
        </Section>
      </Layout>
      <style></style>
    </>
  );
};

const UltimateSalesPage = ({ data }) => {
  return <UltimateSalesPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default UltimateSalesPage;

export const pageQuery = graphql`
  query UltimateSalesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          kicker
          title
          subtitle
          video {
            cloudflareId
            vimeoObject {
              vimeoId
              vimeoHash
            }
            hasGifThumbnail
            embeddedCode
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        famousObject {
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            mobileImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        greetingsObject {
          greeting
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          benefitsObject {
            title
            list {
              iconName
              description
            }
          }
          finalImageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        youKnowObject {
          title
          introduction
          quoteObject {
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            quote
          }
          benefitsObject {
            title
            list {
              description
              iconName
            }
          }
        }
        mindfulnessObject {
          text
          quote
          benefitsText
        }
        invitationObject {
          title
          benefitsObject {
            title
            list {
              description
              iconName
            }
            postScript
          }
          quote
          text
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        innerPeaceObject {
          kicker
          title
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          text
          ctaObject {
            linkText
            linkUrl
          }
          benefitsGridObject {
            list {
              description
              title
              iconName
            }
          }
        }
        testimonialTeaser {
          quoteObject {
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            quote
          }
          author
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        aboutObject {
          title
          benefitsObject {
            title
            list {
              description
              iconName
            }
          }
        }
        academyLifeObject {
          benefitsObject {
            title
            list {
              description
              iconName
            }
          }
          beforeImageObject {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          afterImageObject {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        reviewsObject {
          title
          reviewList {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        coursesContentsObject {
          title
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            description
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        forWhoObject {
          title
          benefitsGridObject {
            list {
              description
              title
            }
          }
        }
        pricingObject {
          regularPricePerMonth
          description
          codeOffer
          descriptionOffer
          pricingCardRegular {
            isRecommended
            title
            price
            priceAdditive
            priceHint
            featuresList
            ctaObject {
              linkText
              linkUrl
            }
            footNote
            priceOffer
            priceOfferHint
          }
          pricingCardRecommended {
            isRecommended
            title
            price
            priceAdditive
            priceHint
            featuresList
            ctaObject {
              linkText
              linkUrl
            }
            footNote
            priceOffer
            priceOfferHint
          }
          postScript
        }
        contentsObject {
          title
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            description
          }
        }
        testimonialsGridObject {
          title
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            author
            description
          }
        }
        coursePackageObject {
          title
          video {
            cloudflareId
            hasGifThumbnail
          }
          benefitsObject {
            list {
              iconName
              description
            }
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        guaranteeObject {
          title
          description
          ctaObject {
            linkText
            linkUrl
          }
        }
        coachesObject {
          title
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            title
            description
          }
        }
        faqsObject {
          title
          qAndA {
            question
            answer
          }
        }
        faqsCtaObject {
          linkText
          linkUrl
        }
        testimonialVideoObject {
          title
          video {
            cloudflareId
            hasGifThumbnail
          }
        }
        finalTextObject {
          title
          description
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
      }
    }
  }
`;
