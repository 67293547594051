import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import IconSelect from "./utilities/IconSelect";
import { StaticImage } from "gatsby-plugin-image";

const BenefitsGrid = ({
  benefitsGridObject,
  classNameTitle = "",
  classNameIcon = "",
  classNameListText = "",
  classNameListTitle = "",
  whitespaceList = "pre-line",
  isIconCentered = false,
  hasEnzo = false,
}) => {
  return (
    <>
      {benefitsGridObject?.title && (
        <RenderMarkdown
          markdownContent={benefitsGridObject.title}
          className={`text-center mb-5 sm:mb-10 ${classNameTitle}`}
        />
      )}
      <div className={`${hasEnzo && "relative mb-5 sm:mb-10"}`}>
        <ul className="flex flex-col md:flex-row md:flex-wrap max-w-5xl mx-auto p-5">
          {benefitsGridObject.list.map((el) => {
            return (
              <li className="flex flex-col space-y-3 md:w-1/2 p-5">
                <div
                  className={`flex items-center ${
                    el.iconName ? "space-x-3 transform -translate-x-2" : ""
                  }`}
                >
                  {el.iconName && (
                    <div
                      className={`${
                        isIconCentered && "flex justify-center items-center"
                      }`}
                    >
                      <IconSelect
                        iconName={el.iconName}
                        className={`w-10 h-10 ${classNameIcon}`}
                      />
                    </div>
                  )}
                  <RenderMarkdown
                    markdownContent={el.title}
                    className={`${classNameListTitle} w-full`}
                    isMarkdown={true}
                    whitespace="normal"
                  />
                </div>
                {!el.iconName && (
                  <hr className="w-2/3 border-[0.05rem] bg-brand-black border-brand-black rounded-full" />
                )}

                {/* w-5/6 to ensure that Icons have enough space to stay at the same size together */}
                <div className="my-auto xl:w-auto">
                  <RenderMarkdown
                    markdownContent={el.description}
                    className={classNameListText}
                    isMarkdown={true}
                    whitespace={whitespaceList}
                  />
                </div>
              </li>
            );
          })}
        </ul>
        {hasEnzo && (
          <StaticImage
            aria-hidden
            className="absolute w-10 sm:w-20 bottom-0 left-1/2 right-1/2 transform -translate-x-1/2 translate-y-1/2"
            src="../img/imported_with_StaticImage/enzo-transparent-background.png"
          />
        )}
      </div>
      {benefitsGridObject.postscript && (
        <RenderMarkdown
          markdownContent={benefitsGridObject.postscript}
          className="text-center mt-5 sm:mt-10"
        />
      )}
    </>
  );
};

export default BenefitsGrid;
