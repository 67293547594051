import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import ConditionalWrap from "./utilities/ConditionalWrap";
import TrackedLink from "./utilities/TrackedLink";
import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";
import BrandButton from "./BrandButton";

const FancyContentsList = ({
  contentsObject,
  classNameCard = "",
  classNameTitle = "text-center",
  isEvenOdd = false,
  mobileImagesFullSize = false,
}) => {
  return (
    <div className="space-y-8 lg:space-y-14">
      <RenderMarkdown
        markdownContent={contentsObject.title}
        className={`${classNameTitle}`}
      />
      <ul className="flex flex-col space-y-10 lg:space-y-32 lg:ml-32">
        {contentsObject.list.map((el, index) => {
          if (!isEvenOdd || index % 2 === 0) {
            return (
              <li
                className={`flex flex-col items-center lg:items-start lg:justify-center lg:flex-row ${classNameCard}`}
              >
                <div className="lg:w-1/2 xl:w-1/3">
                  <ConditionalWrap
                    condition={el.imageObject.linkUrl}
                    wrap={(children) => (
                      <TrackedLink href={el.imageObject.linkUrl}>
                        {children}
                      </TrackedLink>
                    )}
                  >
                    <PreviewCompatibleImage
                      imageObject={el.imageObject}
                      loading="lazy"
                      objectFit="cover"
                      className={`rounded-3xl -mb-10 lg:-mb-0 lg:-mt-14 object-cover w-full ${
                        !mobileImagesFullSize && "h-40 lg:h-full"
                      }`}
                    />
                  </ConditionalWrap>
                </div>

                <div className="w-full lg:w-1/2 xl:w-2/3 lg:-ml-24 lg:mr-40 flex flex-col lg:z-10 bg-white p-5 sm:px-10 pt-14 lg:p-8 rounded-3xl max-w-2xl">
                  {el.title && (
                    <RenderMarkdown
                      markdownContent={el.title}
                      className="mb-7 w-full"
                      whitespace="normal"
                    />
                  )}
                  <RenderMarkdown
                    markdownContent={el.description}
                    isMarkdown={true}
                  />
                </div>
              </li>
            );
          } else {
            return (
              <li
                className={`flex flex-col items-center lg:items-start lg:flex-row-reverse ${classNameCard}`}
              >
                <div className="lg:w-1/2 xl:w-1/3">
                  <ConditionalWrap
                    condition={el.imageObject.linkUrl}
                    wrap={(children) => (
                      <TrackedLink href={el.imageObject.linkUrl}>
                        {children}
                      </TrackedLink>
                    )}
                  >
                    <PreviewCompatibleImage
                      imageObject={el.imageObject}
                      loading="lazy"
                      objectFit="cover"
                      className={`rounded-3xl -mb-10 lg:-mb-0 lg:-mt-14 object-cover w-full ${
                        !mobileImagesFullSize && "h-40 lg:h-full"
                      }`}
                    />
                  </ConditionalWrap>
                </div>

                <div className="w-full lg:w-1/2 xl:w-2/3 lg:-mr-24 lg:ml-40 flex flex-col lg:z-10 bg-white p-5 sm:px-10 pt-14 lg:p-8 rounded-3xl max-w-2xl">
                {el.title && (
                    <RenderMarkdown
                      markdownContent={el.title}
                      className="mb-7 w-full"
                      whitespace="normal"
                    />
                  )}
                  <RenderMarkdown
                    markdownContent={el.description}
                    isMarkdown={true}
                  />
                </div>
              </li>
            );
          }
        })}
      </ul>
      {contentsObject.ctaObject && (
        <div className="flex justify-center">
          <BrandButton href={contentsObject.ctaObject.linkUrl}>
            <RenderMarkdown
              markdownContent={contentsObject.ctaObject.linkText}
              whitespace="normal"
            />
          </BrandButton>
        </div>
      )}
    </div>
  );
};

export default FancyContentsList;
